import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { BulkCubeInfo } from '@/wms/domain/bulk/BulkCubeInfo';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';
import { GroupedBulk } from '@/wms/domain/bulk/GroupedBulk';
import { WarehouseBulk } from '@/wms/domain/bulk/WarehouseBulk';
import { Store } from '@/wms/domain/layout/store/Store';

@Injectable()
export class BulkServices implements BulkRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_FIND_BULKS_IN_STOCK: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_FIND_BULKS_IN_STOCK;
  private readonly API_BULKS_UPDATE_PICKING_STATUS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BULKS_UPDATE_PICKING_STATUS;
  private readonly API_BULKS_FIND_FOR_PICKING: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BULKS_FIND_FOR_PICKING;
  private readonly API_BULKS_FIND_FOR_PICKING_BY_STORE_AND_DATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BULKS_FIND_FOR_PICKING_BY_STORE_AND_DATE;
  private readonly API_BULKS_CONSOLIDATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BULKS_CONSOLIDATE;
  private readonly API_BULKS_UN_CONSOLIDATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BULKS_UN_CONSOLIDATE;
  private readonly API_BULKS_FIND_CONSOLIDATED: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BULKS_FIND_CONSOLIDATED;
  private readonly API_FIND_FREIGHT_BULKS_IN_STOCK: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_FIND_FREIGHT_BULKS_IN_STOCK;
  private readonly API_FREIGHT_BULKS_FIND_FOR_PICKING: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_FREIGHT_BULKS_FIND_FOR_PICKING;
  private readonly API_FIND_BULKS_FOR_PICKING_BY_HOUSE_BL: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_FIND_BULKS_FOR_PICKING_BY_HOUSE_BL;
  private readonly API_FIND_BULKS_BY_PICKING_LISTS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_FIND_BULKS_BY_PICKING_LISTS;
  private readonly API_BULKS_FIND_DATA_FOR_WAREHOUSE_RECEIPT_REPORT: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BULKS_FIND_DATA_FOR_WAREHOUSE_RECEIPT_REPORT;
  private readonly API_BULKS_FIND_CUBE_INFO: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BULKS_FIND_CUBE_INFO;
  private readonly API_BULKS_CUBE_PROCESS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BULKS_CUBE_PROCESS;
  private readonly API_BULKS_FIND_BY_OPERATION: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BULKS_FIND_BY_OPERATION;

  async findBulksInStock(filters: { entity?: Entity | null; store?: Store | null }): Promise<Bulk[]> {
    try {
      let QUERY = `${this.API_FIND_BULKS_IN_STOCK}`;

      //Si no se envia el parametro entity, se retornan todos los bultos en stock de todas las entidades
      if (filters.entity) QUERY += `entity=${filters.entity.id}`;
      //Si no se envia el parametro store, se retornan todos los bultos en stock de todas las tiendas
      if (filters.store && !filters.entity) QUERY += `store=${filters.store.storeId}`;
      if (filters.store && filters.entity) QUERY += `&store=${filters.store.storeId}`;

      const promiseRes1 = http.get<Bulk[]>(`${QUERY}`);
      const promiseRes2 = http.get<Bulk[]>(
        `${QUERY.replace(this.API_FIND_BULKS_IN_STOCK, this.API_FIND_FREIGHT_BULKS_IN_STOCK)}`
      );
      const [res1, res2] = await Promise.all([promiseRes1, promiseRes2]);
      return res1.concat(res2);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async updateBulkPickingStatus(payload: { bulks: string[]; status: boolean; carrier: number }): Promise<Bulk[]> {
    try {
      return await http._put<Bulk[]>(
        `${this.API_BULKS_UPDATE_PICKING_STATUS}forPicking=${payload.status}&carrier=${payload.carrier}`,
        JSON.stringify(payload.bulks)
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findPickedBulks(filters: {
    entity?: Entity | null | undefined;
    store?: Store | null | undefined;
  }): Promise<Bulk[]> {
    try {
      let QUERY = `${this.API_BULKS_FIND_FOR_PICKING}`;

      //Si no se envia el parametro entity, se retornan todos los bultos en stock de todas las entidades
      if (filters.entity) QUERY += `entity=${filters.entity.id}`;
      //Si no se envia el parametro store, se retornan todos los bultos en stock de todas las tiendas
      if (filters.store && !filters.entity) QUERY += `store=${filters.store.storeId}`;
      if (filters.store && filters.entity) QUERY += `&store=${filters.store.storeId}`;

      const promiseRes1 = http.get<Bulk[]>(`${QUERY}`);
      const promiseRes2 = http.get<Bulk[]>(
        `${QUERY.replace(this.API_BULKS_FIND_FOR_PICKING, this.API_FREIGHT_BULKS_FIND_FOR_PICKING)}`
      );
      const [res1, res2] = await Promise.all([promiseRes1, promiseRes2]);
      return res1.concat(res2);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findPickedBulksByStoreAndDate(filters: {
    store?: Store | null | undefined;
    dateFrom: string;
    dateTo: string;
    origin: string;
    destination: string;
  }): Promise<Bulk[]> {
    try {
      let QUERY = `${this.API_BULKS_FIND_FOR_PICKING_BY_STORE_AND_DATE}`;

      //Si no se envia el parametro entity, se retornan todos los bultos en stock de todas las entidades
      if (filters?.store) QUERY += `store=${filters.store.storeId}`;
      //Si no se envia el parametro store, se retornan todos los bultos en stock de todas las tiendas
      if (filters?.dateFrom) QUERY += `&dateFrom=${filters.dateFrom}`;
      if (filters?.dateTo) QUERY += `&dateTo=${filters.dateTo}`;
      if (filters?.origin) QUERY += `&origin=${filters.origin}`;
      if (filters?.destination) QUERY += `&destination=${filters.destination}`;

      return await http.get<Bulk[]>(`${QUERY}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findConsolidatedBulks(filters: {
    store?: Store | null | undefined;
    dateFrom: string;
    dateTo: string;
    origin: string;
    destination: string;
  }): Promise<Bulk[]> {
    try {
      let QUERY = `${this.API_BULKS_FIND_CONSOLIDATED}`;

      //Si no se envia el parametro entity, se retornan todos los bultos en stock de todas las entidades
      if (filters?.store) QUERY += `store=${filters.store.storeId}`;
      //Si no se envia el parametro store, se retornan todos los bultos en stock de todas las tiendas
      if (filters?.dateFrom) QUERY += `&dateFrom=${filters.dateFrom}`;
      if (filters?.dateTo) QUERY += `&dateTo=${filters.dateTo}`;
      if (filters?.origin) QUERY += `&origin=${filters.origin}`;
      if (filters?.destination) QUERY += `&destination=${filters.destination}`;

      return await http.get<Bulk[]>(`${QUERY}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @name consolidateBulks
   * @description Endpoint para consolidar bultos
   * @param serials Array de serials de bultos a consolidar
   * @returns Promise<Bulk[]>
   */
  consolidateBulks(serials: string[]): Promise<Bulk[]> {
    try {
      return http._put<Bulk[]>(`${this.API_BULKS_CONSOLIDATE}`, JSON.stringify(serials));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  /**
   * @name consolidateBulks
   * @description Endpoint para consolidar bultos
   * @param serials Array de serials de bultos a consolidar
   * @returns Promise<Bulk[]>
   */
  deconsolidateBulks(serials: string[]): Promise<Bulk[]> {
    try {
      return http._put<Bulk[]>(`${this.API_BULKS_UN_CONSOLIDATE}`, JSON.stringify(serials));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  findBulksByHouseBL(houseBL: string) {
    try {
      return http.get<Bulk[]>(`${this.API_FIND_BULKS_FOR_PICKING_BY_HOUSE_BL}houseBL=${houseBL}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findByPickingLists(pickingLists: number[]) {
    try {
      return await http.get<GroupedBulk[]>(
        `${this.API_FIND_BULKS_BY_PICKING_LISTS}pickingLists=${pickingLists.join(',')}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findForPicking(filters: {
    dateFrom: string;
    dateTo: string;
    commodity: CommodityType | null;
    customer: Entity | null;
    masterBl: string | null;
    houseBl: string[] | null;
    consignee: string | null;
  }): Promise<GroupedBulk[]> {
    try {
      const { commodity, customer, masterBl, houseBl, dateFrom, dateTo, consignee } = filters;

      let QUERY = `${this.API_BULKS_FIND_FOR_PICKING}`;

      if (dateFrom) QUERY += `dateFrom=${dateFrom}`;

      if (dateTo) QUERY += `&dateTo=${dateTo}`;

      if (commodity) QUERY += `&commodity=${commodity.id}`;

      if (customer) QUERY += `&entity=${customer.code}`;

      if (masterBl) QUERY += `&masterBl=${masterBl}`;

      if (houseBl && houseBl.length > 0) {
        QUERY += `&houseBl=${houseBl}`;
      }

      if (consignee) QUERY += `&consignee=${consignee}`;

      return await http.get<GroupedBulk[]>(QUERY);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  findForWarehouseReceipt({ numberDoc, typeDoc }: { numberDoc: number; typeDoc: string }) {
    try {
      return http.get<WarehouseBulk>(
        `${this.API_BULKS_FIND_DATA_FOR_WAREHOUSE_RECEIPT_REPORT}numberDoc=${numberDoc}&typeDoc=${typeDoc}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  findCubeInfo(payload: { type: string; number: number }) {
    try {
      return http.get<BulkCubeInfo[]>(`${this.API_BULKS_FIND_CUBE_INFO}type=${payload.type}&number=${payload.number}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  cubeProcess(payload: { type: string; number: number; cubeInfo: BulkCubeInfo[] }): Promise<BulkCubeInfo[]> {
    try {
      return http._put<BulkCubeInfo[]>(
        `${this.API_BULKS_CUBE_PROCESS}type=${payload.type}&number=${payload.number}`,
        JSON.stringify(payload.cubeInfo)
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  findByOperation(payload: { number: number; type: string }) {
    try {
      return http.get<Bulk[]>(`${this.API_BULKS_FIND_BY_OPERATION}number=${payload.number}&type=${payload.type}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
